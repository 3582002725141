import React, { Suspense, lazy } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Layout from './components/Containers/Layout/Layout.jsx'
import PageLoader from './components/UI/PageLoader/PageLoader'

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path='*' element={<HomeLazy />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
