import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import css from './Layout.module.scss'
import Logo from '../../../assets/logo_horizontal.svg'

const Layout = () => (
  <Fragment>
    <div className={css.Container}>
      <div className={css.Content}>
        <Outlet />
      </div>
    </div>

    <div className={css.Footer}>
      &copy;{new Date().getFullYear()} NukeTown.io -{' '}
      {document.head.querySelector('meta[name="build-version"]')['content']}
      <br />
      <br />
      Anomaleet SRL - VAT 10289790965
      <br />
      Via Pace 17 Carnate 20866 MB - Italy
      <img src={Logo} alt='NukeTown' className={css.Logo} />
    </div>
  </Fragment>
)

export default Layout
